import React from "react"
import { Card, Row, Col } from "antd"
import {
  ContainerContent,
  Layout,
  SEO,
  ContainerContentFixed,
  BannerPage,
  Colors,
  InternalLinkTypes,
  ContainerCta,
  InternalLink,
  ButtonLink
} from "../components"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons"

const ServicesPage = ({ data }) => {
  const services = data.allServicesJson.nodes
  return (
    <Layout>
      <SEO
        title="Services"
        description={"List of the services provided by the UCC team"}
      />
      <BannerPage
        title={"Services"}
        subTitle={"List of the services provided by the UCC team"}
      />
      <ContainerContent style={{ background: Colors.White }}>
        <ContainerContentFixed>
          <Row gutter={[16, 16]}>
            {services.map(s => (
              <Col key={s.id} lg={s.cols} xs={24}>
                <Card cover={<Img fluid={s.imagePath.childImageSharp.fluid} />}>
                  <Card.Meta
                    title={
                      <InternalLink url={s.slug}>
                        <span style={{ color: Colors.Blue }}>{s.name}</span>
                      </InternalLink>
                    }
                    description={s.description}
                  />
                  <br />
                  <ButtonLink style={{ marginLeft: "-16px" }}>
                    <InternalLink url={`/services/${s.id}`}>
                      Learn more <FontAwesomeIcon icon={faAngleDoubleRight} />
                    </InternalLink>{" "}
                  </ButtonLink>
                </Card>
              </Col>
            ))}
          </Row>
        </ContainerContentFixed>
      </ContainerContent>

      <ContainerCta
        title={"Hire us and see the quality of work"}
        secondaryText={"Get Quote"}
        secondaryLink={InternalLinkTypes.Quote}
      />
      <Img
        style={{ width: "100%", height: "400px" }}
        fluid={data["Bg"].childImageSharp.fluid}
      />
    </Layout>
  )
}

export const query = graphql`
  query {
    Bg: file(relativePath: { eq: "6.png" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allServicesJson {
      nodes {
        id
        name
        cols
        slug
        description
        imagePath {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default ServicesPage
